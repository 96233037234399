import React from 'react'
import Right from '../header/Right'
import { gamelevel } from '../AllApi/GameLeveApi';
import loader from '../../images/loader.gif'
import { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom'
import CateHeader from '../header/CateHeader'
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../AllApi/CommonUrl';
import Navbar from '../header/Navbar';
import Cookies from 'js-cookie';
import QuizFooter from '../footer/QuizFooter';
import Swal from 'sweetalert2';
const GameLevel = () => {
    const url = window.location.href.split('/')[2];
    const user_id = Cookies.get('userId')
    const tok = Cookies.get('idtoken')
    let { constid } = useParams();
    const [level, setLevel] = useState([]);
    const [res, setRes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [slow, setslow] = useState(false);
    const navigate = useNavigate()
    const [ClickValue, SetClickvalue] = useState(false);
    const Internet=()=>{
        Swal.fire({
          title: "Network issue",
          text:"Weak  internet connection",
          allowOutsideClick: false,
          icon:'error'
        });
      }
    useEffect(() => {
        ; (async () => {
            if (!user_id) {
                navigate("/");
            }
            try {
                const LevelEndPoint = 'goldgame/gameLevels'
                const result = await gamelevel(constid, user_id, BASE_URL, LevelEndPoint, tok);
                if(result==0){
                    Internet();
                    setslow(true)
                   }
                setRes(result)
                setLevel(result.alllavels)
                setIsLoading(false);
            } catch (error) {
                console.log(error)
            }
        })()
    }, []);

    function api(levelId) {
        SetClickvalue(true);
        // var link =`http://testing2.mchamplite.com/knifefling/?userId=${9}&contestId=${3}&levelNameId=${5}&newurl=http://localhost:3000/GamePlay`
        var link = `${res.game_url}?userId=${user_id}&contestId=${constid}&levelNameId=${levelId}&newurl=https://${url}/GamePlay`
        window.location.href = link;
    }
    const CompleteAlert = () => {
        Swal.fire({
            title: "Thanks for Completing all Levels!",
            html: `<p class="textsize">The Winner will be Announced after Tournament End Time.</p><p>Your total score is ${level[4].stoal_core1}</p>
      `,
            icon: "success",
            allowOutsideClick: false,
            showConfirmButton: true
        })
    }
    return (
        <>
            <div className="section">
                <div className="s_left">
                    {/* commented by amit sir */}
                    <CateHeader name={'Game Level'} path={`/GameDetails/${constid}`}></CateHeader>
                    {isLoading && <div className='center_vh'>
                        <img src={loader} alt="loader"></img>
                    </div>
                    }

                    {!isLoading&& <div className='game_detail3'>
                        <div class="g_tp_img">
                            <img src={res.games_banner} />
                        </div>
                    </div>}
                    {res.levelCompleteStatus==1 && CompleteAlert()}
                    {!isLoading&&!slow&&<div className="game_det">
                        <div className="flexx">
                            <p class="game_ttl">{res.title}</p>
                            {/* <button className="htp">How To Play</button> */}
                            <p class="game_ttl">Total Score :{res.alllavels[4].stoal_core1
                            }</p>
                        </div>
                        <div className="">
                            {level != null &&
                                level.map((key, index) => {
                                    return (
                                        <div className="game g2_new">
                                            <div className="game_img level_img">
                                                <img src={key.lavel_icon} alt="level1" />
                                            </div>
                                            <div className="game_info g_inf2">
                                                {/* <p>Level {key.lavel_name}</p> */}

                                                <p>{key.alpha == 1 && key.levelbuttontext == "Completed" && <span>Score:{key.score}</span>}</p>
                                                {/* <a  href={`${res.game_url}/${1}`}> */}
                                                {
                                                    key.alpha == 1 && key.levelbuttontext == "Completed" &&
                                                    <button className={`btn_white btn_gren disable btn_levl`}>{key.levelbuttontext}</button>

                                                }
                                                {
                                                    key.alpha == 1 && key.levelbuttontext == "Play Now" &&
                                                    <button className={`btn_white btn_gren disable  ply_nw_btn btn_levl`}>{key.levelbuttontext}</button>

                                                }
                                                {key.alpha == 0 && !ClickValue &&
                                                    <button className={`btn_white btn_gren btn_levl`} onClick={() => api(key.lavel_name)} >{key.levelbuttontext}</button>
                                                }
                                                {key.alpha == 0 && ClickValue &&
                                                    <button className={`btn_white btn_gren btn_levl disable`}>{key.levelbuttontext}</button>
                                                }

                                                {/* </a> */}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {res.levelCompleteStatus == 1 &&
                                <div className='mr_20'>
                                    <p className="game_desc desc2 center">Thanks for Completing all Levels!

                                        <span className='game_desc '>The Winner will be Announced after Tournament End Time.</span>

                                    </p>
                                    <NavLink to={`/GameDetails/${constid}`}>
                                        <button className={`btn_white btn_gren mt_10`} >LeaderBoard</button>
                                    </NavLink>
                                </div>
                            }
                        </div>
                    </div>}
                    <QuizFooter></QuizFooter>
                </div>
                <Right></Right>
            </div>
        </>
    )
}

export default GameLevel
