import React from 'react'
import CateHeader from '../header/CateHeader'
import WalletInfo from '../Progress/WalletInfo'
import dollar from '../../images/celo_dollar.png'
import loader from '../../images/loader.gif'
import Right from '../header/Right'
import coin from '../../images/coin.png'
import coin1 from '../../images/coins_bg.png'
import diamond1 from '../../images/diamond_bg.png'
import credit from '../../images/credit.png'
import debit from '../../images/debit.png'
import diamond from '../../images/diamond.png'    
import { walletData } from '../AllApi/WalletApi';
import { BASE_URL} from '../AllApi/CommonUrl';
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { NavLink } from 'react-router-dom'
import { History } from '../AllApi/History';
import { useNavigate } from "react-router-dom";
import { Redeme } from '../AllApi/ReedemNow';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom'
const ProgressRoute = () => {
  const user_id= Cookies.get('userId')
  const tok=Cookies.get('idtoken')
  const redeem=async(quantity)=>{
    try {
      const endpoint='offer/redeemUserDiamondApi/'
       const result= await Redeme(user_id,quantity,BASE_URL,endpoint,tok);
       if(result.status==1){
        RedeeAlert()
      }
       console.log('redeem',result);
       if(result.result=='Invalid Reedem Request'){
        InvalidRedeeAlert()
       }
    }
    catch{}
  }
  const RedeeAlert=()=>{
    Swal.fire({
      title:"Diamond Redeemed Successfully!",
      html: `Redeem Request Received.<p>cUSD will be transferred to your minipay wallet account within 24 hours.</p>
    `,
      showDenyButton: false,
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      icon:"success"
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/')
      }
      else if (result.isDenied) {
      }
    });
  }
  const InvalidRedeeAlert=()=>{
    Swal.fire({
      title: "Invalid Diamond Reedem Request!",
      showDenyButton: false,
      confirmButtonText: "Ok",
      allowOutsideClick: false
    })
  }
  const RedeemDiamond = (diamond) => {
    Swal.fire({
      title: "Claim Request confirm ?",
      showDenyButton: true,
      confirmButtonText: "Confirm",
      denyButtonText: `Not Now`,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        redeem(diamond)
      }
      else if (result.isDenied) {
      }
    });
  }
  let { flag } = useParams();
  const navigate = useNavigate()
    const[coins,setCoins]=useState([]);
    const[diamonds,setDiamonds]=useState('');
    const [isLoading, setIsLoading] = useState(true);
    const[hist,sethist]=useState([]);
    const Internet=()=>{
      Swal.fire({
        title: "Network issue",
        text:"Weak  internet connection",
        allowOutsideClick: false,
        icon:'error'
      });
    }
    useEffect(() => {
          ; (async () => {
            if(!user_id){
              navigate("/");
            }
            try {
                 const WalletEndPoint='userheaderdetail/homecategory'
              const result = await walletData(user_id,BASE_URL,WalletEndPoint,tok);
              if(result==0){
                Internet();
               } // Replace with your endpoint
              const EndPoint='offer/redeemUserDiamondHistory'
              const resp = await History(user_id,BASE_URL,EndPoint,tok);
              sethist(resp.result)
              setCoins(result.data.wallet.coin_balance)
              setDiamonds(result.data.wallet.diamond_balance)
              if(result.data.wallet.diamond_balance>0 && flag==2){
                RedeemDiamond(result.data.wallet.diamond_balance)
              }
              setIsLoading(false)
                  } catch (error) {
              console.log(error)
            }
          })()
        }, []);
    return (
        <>
            <div className="section">
                <div className="s_left">
                    <CateHeader name={'Wallet'} path={'/gameroute/1'}></CateHeader>
                    <div className="game_list">
                    
{isLoading && <div className='center_vh'>
              <img  src={loader} alt="loader"></img>
            </div>
          }
                      {isLoading===false && 
         <WalletInfo name={'Total Coins'} value={coins} image={coin} limage={coin1} status={1} user_id={user_id} tok={tok}></WalletInfo>     
          }
           {isLoading===false && 
              <WalletInfo name={'Total Diamonds'}  value={diamonds} image={diamond}  limage={diamond1} status={2} user_id={user_id} tok={tok}></WalletInfo>}
                
                <div class="flexx pdng_10">
                  <p class="l_ttl">Transaction History</p>
                  <NavLink  to={"/history"}>
                  <button class="htp">See All</button>
                  </NavLink>
                </div>
   {/* {
        isLoading===false && hist.length==0 && <h6 style={{color:"red",textAlign:"center"}}>No history </h6>
      } */}
               {  isLoading===false && hist!=null&& 
  hist.map((key,index) => {
    return(
      <div class="ldr_list">
      <div class="ldr_itm">
        <div className='ldr_sb_itm'>
        {key.msg!=null && key.credit_status==1&&key.transaction_type==1 &&
            <img src={credit} alt='credit' />
            }
              {key.msg!=null && key.credit_status==1&&key.transaction_type==3 &&
            <img src={credit} alt='credit' />
            }
            {key.msg!=null && key.credit_status==1&&key.transaction_type==4 &&
            <img src={credit} alt='credit' />
            }
             {key.msg!=null && key.credit_status==2&& key.transaction_type==1 &&
            <img src={debit} alt='credit' />
            }
         
         {  key.credit_status==1&&key.transaction_type==2 &&
            <img src={credit} alt='credit' />
            }
             { key.credit_status==2&& key.transaction_type==2 &&
            <img src={debit} alt='credit' />
            }
         
          <div>                             
          {key.msg!=null &&  key.credit_status==1&& key.transaction_type==1 &&
            <p className='ldr_sb_itm'>Bought Coin </p>
            }
             {key.msg!=null &&  key.credit_status==2&& key.transaction_type==1 &&
           
           <div> <p className='ldr_sb_itm'>Deduct Coin </p>
            <p className='ldr_sb_itm g_name_walt'>{key.title}</p>
           </div>
           }
              {  key.credit_status==1&& key.transaction_type==3 &&
            <p className='ldr_sb_itm'>cUSD Transferred</p>
            }
             {  key.credit_status==1&& key.transaction_type==4&&
            <p className='ldr_sb_itm'>cUSD Transferring</p>
            }
              {  key.credit_status==1&& key.transaction_type==2 &&
              <div><p className='ldr_sb_itm'>Won Diamond </p>
              <p className='ldr_sb_itm g_name_walt'>{key.title}</p>
             </div>
            }
            { key.credit_status==2&& key.transaction_type==2 &&
            <p className='ldr_sb_itm'>Redeemed Diamond </p>
            }
            <p className='tming'>{key.create_date}</p>
          </div>
        </div>
        {key.transaction_type==1 &&
         <div class="ldr_sb_itm entry_value">
         <p class="etry_am">
           <img src={coin}alt="diamond"></img>
           {key.diamond_quantity}
         </p>
       </div>
        }
        {key.transaction_type==2&&
         <div class="ldr_sb_itm entry_value">
         <p class="etry_am">
           <img src={diamond}alt="diamond"></img>
           {key.diamond_quantity}
         </p>
       </div>
        }
         {key.transaction_type==3&&
         <div class="ldr_sb_itm entry_value">
         <p class="etry_am">
           <img src={dollar}alt="diamond"></img>
           {key.diamond_quantity}
         </p>
       </div>
        }
        {key.transaction_type==4&&
         <div class="ldr_sb_itm entry_value">
         <p class="etry_am">
          In Process
         </p>
       </div>
        }
        
        
      </div>
  </div>
    )
  })
} 

                </div>                
                
               </div>
                <Right></Right>
            </div>
        </>
    )
}

export default ProgressRoute  
