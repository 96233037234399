import Cookies from 'js-cookie';
const COOKIE_NAME = 'userId';
const  COOKIE_TOKE= 'idtoken';
const url=window.location.href.split('/')[2]; 
//genrate random user id
const generateRandomId = async() => {
  if (window && window.ethereum) {
  
    if (window.ethereum.isMiniPay) {
      // User is using Minipay
  
      // Requesting account addresses
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [],
      });
      // Injected wallets inject all available addresses,
      // to comply with API Minipay injects one address but in the form of array
      //console.log(accounts[0]);
       return accounts[0];
    }
    }
    
};
// store new user in db 
const ApiCall = async(uid) => {
  try {
    const Url = "https://tournamentapi.mchamp.xyz/loginorregister";
    const Idtoken ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY3Nzk5IiwidGltZVN0YW1wIjoiMjAyNC0wNS0xNyAwNTo1NTo0OSJ9.Dkt4VhHOJXINdDxwX1vPVmBQMhJFZ73FkJOBaVTtj78";
    const rawData ={
        "android_id":uid,
        "gaid":uid,
        "mobile_number":uid,
        "login_type":"3",
        "email_id":"",
        "is_tc_accepted":"Yes",
        "imsi":"132",
        "imei":"1212",
        "brand":"",
        "model":"",
        "operator":"",
        "connection_type":"",
        "apk_version":"33",
        "android_version":"33",
        "package_version":"",
        "fb_first_name":"",
        "fb_last_name":"",
        "fb_email_id":"",
        "fb_image_url":"",
        "fb_id":"",
        "gm_first_name":"",
        "gm_last_name":"",
        "gm_email_id":"",
        "gm_image_url":""
        }
    const response = await fetch(Url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Idtoken}`,
      },
      body: JSON.stringify(rawData),
    });
    if (!response.ok) {
      throw new Error('some error');
    }
    const jsonData = await response.json(); 
    Cookies.set(COOKIE_NAME, jsonData.user_id,{ expires: 1 }); 
    Cookies.set(COOKIE_TOKE, jsonData.idtoken,{ expires: 1 }); 
    console.log('data',jsonData.user_id);
    console.log('tokdd',jsonData.idtoken);
        }
        catch (error) {
            console.error('Error fetching data:', error);
            //throw error;
	    return 0;
          }

};
// Genrate new user 
export const checkAndHandleUserId  =(navigate) => {
    var CookeId = Cookies.get(COOKIE_NAME);
        const myFirstPromise = new Promise((resolve, reject) => {
          resolve( generateRandomId())
        });
        myFirstPromise.then((successMessage) => {
          if (typeof navigate !== 'function') {
            console.error("Navigate is not a function");
            return;
          }
          if( typeof(successMessage)=='undefined'){
            navigate('/restricted')
          }
          else{
            ApiCall(successMessage) 
          }
        });
    return {
        useId :Cookies.get(COOKIE_NAME),
        BASE_URL : 'https://tournamentapi.mchamp.xyz/',
        tok :Cookies.get(COOKIE_TOKE) 
    };
  };
let x=checkAndHandleUserId();
    export const BASE_URL=x.BASE_URL
    export const user_id=x.useId
    export const tok=x.tok
