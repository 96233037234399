import React from 'react'
import { NavLink } from 'react-router-dom'

const QuizFooter = () => {
  return (
   <>
  <div className="footernav1">
  {/*url footer*/}
  <div className="d_flex" style={{ justifyContent: "space-evenly" }}>
    <NavLink to={'/howplay'}>How to Play</NavLink>
    <NavLink to={'/faq'}>FAQ</NavLink>
    <NavLink to={'/term'}>Terms &amp; Conditions</NavLink>
    <NavLink to={'/policy'}>Privacy Policy</NavLink>
  </div>
  <p>Copyright @ mChamp Entertainment Pvt Ltd </p>
  <p>2024 All rights reserved.</p>
  <p class="d_flex">Disclaimer: These contests are strictly skill-based and require the use of strategy and skill to succeed. They do not constitute gambling or games of chance.
  </p>
</div>

   </>
  )
}

export default QuizFooter
