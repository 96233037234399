import React from 'react'
import Right from '../header/Right'
import CateHeader from '../header/CateHeader'
import { NavLink } from 'react-router-dom'
import QuizFooter from '../footer/QuizFooter'
const TermCondition = () => {
  return (
   <>
   <div className="section">
  <div className="s_left">
    <div className="header">
      <CateHeader name={'Term & Conditions'} path={'/gameroute/1'}></CateHeader>
    </div>
    {/* how to play content*/}
    <div className="htplay game_det">
      <h2 className="text_bg redd">GENERAL TERMS</h2>
      <div className="htP_text">
        <p>
        These terms and conditions (hereinafter referred to as 'Terms') represent a legal and binding contract between you (User/Users) and TrueNetwork Technologies Ltd, a company registered under International Business Companies 1994 in Republic of Seychelles (we/the Company/TrueNetwork).
You can access the Terms at any time at terms and conditions, governing your download, access and/or use of games.mchamp.xyz Website/Application (the Services), and other online services, and the content, features and services therein (the Services). You agree that by accessing the Services, you are agreeing to these terms and our Privacy Policy.
The Company reserves the right to modify the Terms at any time. In this case, you may be asked to accept the changes in order to continue using the Services. In the event that the Terms or future updated versions are not accepted, you must abstain from and interrupt any access to and / or use of the Services.
You agree that you are at least 13 years old and that if you are between 13 years and 18 years old, your legal guardian has reviewed and agrees to these terms and privacy policy for you to use the Services.
        </p>
      </div>
      <h2 className="text_bg redd">
      YOUR ACCOUNT
      </h2>
      <div className="htP_text">
        <p>
        To use the Services of TrueNetwork , you may avail our limited online services through our web or you may create an Account. Your account is personal and can’t be transferred to other people. We will not be responsible to you for any loss that you suffer as a result of an unauthorized person accessing your account and/or using our Services. The Company reserve itself the right to suppose that who accesses to your account using the data of access, is you, or someone authorized by you. If you fail to keep your account in secret, or if you share your account details with someone else (whether intentionally or unintentionally), you accept full responsibility for the consequences of this (including any unauthorised purchases) and agree to fully compensate TrueNetwork  for any losses or harm that may result.
        TrueNetwork  is not responsible to you for any loss that you suffer as a result of an unauthorised person accessing your account and using Services and TrueNetwork  accept no responsibility for any losses or harm resulting from its unauthorised use, whether fraudulently or otherwise.
        TrueNetwork  reserve the right to delete your account if no activity is conducted by you in relation to the account for 180 or more days. In such event, you may no longer be able to access and/or use any Points (as defined below) associated with that account and no compensation/refund will be offered to you in relation to the same.
You understand that if you delete your account, or if we delete your account in accordance with these terms, you may lose access to any data previously associated with your account (including, without limitation, your progress through our app and/or any Points associated with your account).
You acknowledge and agree not to formulate any proprietary rights or interests in accounts created using our Services. The Company reserves the right to suspend, terminate, modify or delete accounts and Services by communicating this with an advance notice of 7 days. However, the Company’s right to extraordinary termination of the contract for just cause without advance notice remains valid.
        </p>
      </div>
      <h2 className="text_bg redd">JUDGING AND SELECTION OF WINNERS:</h2>
      <div className="htP_text">
        <p>
        All decisions of TrueNetwork (Including declaration of the winners of the contests) are final and binding on all parties (including but not limited to user of Services). No correspondence will be entered into regarding the selection of the winner of this prize(s).
In the event there are not enough eligible entries, not all prizes will be awarded. If a winner is unable (for whatever reason) to claim/accept his or her prize within the mentioned time duration, then TrueNetwork reserves the right to disqualify winners from claiming the prize.
Notification of Winners: Winner will be chosen by Computer Automated System; the winner(s) will be selected and notified in app only. If a potential winner does not respond to the notification or does not claim the prize within seven (7) days from the announcement date, then such potential winner(s) will be disqualified.
        </p>
      </div>
      <h2 className="text_bg redd">
      PRIZES
      </h2>
      <div className="htP_text">
        <p>You can now Redeem your Points! Points collected on TrueNetwork  do not have any monetary value. Depending on offers, TrueNetwork  reserves the right to change this denomination at its discretion. There is no cash alternative and the prizes must be taken as offered. TrueNetwork Technologies Ltd. reserves the right to to control, adjust, modify, revise, and/or substitute prize(s) of an equivalent value, should the Contest prize become unavailable for any reason. If any winner is not able (for whatever reason) to accept their prize then, TrueNetwork. reserves the right to award the prize to the next best entry. Any prize(s) that remains unclaimed at the end of 7 days from the date of announcement of the results may be awarded to another Winner. We may ask the winner to submit valid identification and in case of any doubt about the authenticity of any Winner, TrueNetwork Reserves the right to revoke the Prize won, even if the Winner is announced on App. Subsequently TrueNetwork Reserves the right to select another winner for the same prize, from among all the remaining eligible entries. You are eligible for Referral prize only for registration of a unique number from a unique mobile hand set. This Referral Program is applicable on the latest versions of TrueNetwork app for Android Phones only. The latest Version is available on Play store.
Except for warranties and conditions implied by law which cannot be excluded, TrueNetwork . makes no representations or warranties, expressed or implied, regarding the quality or suitability of any prize awarded under these Rules. Some legislation may imply conditions or warranties which cannot be excluded, restricted or modified except to a limited extent. In this event, to the extent permitted by law, the liability of TrueNetwork is limited to, at its option: (i) the replacement of the prize or the supply of an equivalent prize; or (ii) the payment, if it does not contravene any law, of the cost of the replacement or supply. 
A Prize shall neither be transferable nor exchangeable for cash, unless otherwise expressly agreed to by TrueNetwork  Mere participation in the contest shall not entitle you to win the prize.
</p>
      </div>
      <h2 className="text_bg redd">
      Terms & Conditions for Redemption:
      </h2>
      <div className="htP_text">
        <p>
        You are responsible for ensuring that you review the T & C of each offer coupon before adding to your cart. An offer once confirmed cannot be reverted. Offer coupons presented to you for redemption have been procured by TrueNetwork from reputed Distributers on Best effort basis.
TrueNetwork only makes it possible for you to Redeem 'Third Party offer Coupons' on our App on a best intent basis. The sole responsibility of fulfilment of an offer coupon lies with individual Brands to the extent and with-in the scope of the T & C provided by them. Validity of each coupon is as mentioned in the T & C of each offer coupon.

        </p>
      </div>
      <h2 className="text_bg redd">Celebrity Meet & Greet Prize:</h2>
      <div className="htP_text">
        <p>
        TrueNetwork will arrange Meet & Greet with Celebrity on best effort basis. During a Multi Celebrity event, if due to any unforeseen eventuality a Celebrity is unavailable to meet with the Winners, then TrueNetwork  reserves the right to get the TrueNetwork winner to meet another Celebrity without any prior intimation. Further due to any unforeseen reasons, the celebrity Meet & Greet does not take place within 90 days of prize declaration, TrueNetwork will reimburse the winner with Rs. 10,000/- (Ten Thousand only) as non-performing fees, which user explicitly accepts as under this Terms & Conditions without any prejudice. This non-performing fees will be offered only in case of non-performance of Meet & Greet event. This Fees cannot be claimed in anyother circumstances. Also, where Points to attend a concert have been won, TrueNetwork will arrange the same on a best effort basis. However due to any some unforseen reason, we are unable to fulfill this commitment, TrueNetwork will reimburse the winner with a Coupon for Rs.500/- (Five Hundred only) as non-performing fees.
The as non-performing fees  shall collectively be the sole and exclusive remedies of winner(s) with respect to celebrity Meet & Greet event.
TrueNetwork reserves the right to use information about the winner/Participant, to mention/link, Tag/Share/Advertise, in all types of Media where TrueNetwork  is mentioned or is Promoted. 
Winners agree to wear/use/display TrueNetwork  Branded material/merchandise at any/all Promotional events.

        </p>
      </div>
      <h2 className="text_bg redd">
      Brand Centric Contest Terms and Conditions:
      </h2>
      <div className="htP_text">
        <p>TrueNetwork will Host a Brand Centric Contest on best effort basis. TrueNetwork  will Host a Brand Centric Contest on best effort basis. Participation in the Brand Specific Contest, to the extent required by the Brand, is a must in order to be eligible for its prize. The Brand / Agency representing Brand is entitled to, at its discretion and without giving a reason to discontinue, modify or adapt the competition for reasons of its own without any obligation to announce winners or compensate the participant in any manner. In any case TrueNetwork shall not liable to follow through on any promise made by the Brand at the launch of the said contest, with regards to winner declaration or distribution of prizes. 
The prize is personal and is registered in the name of the winner. The prize is not transferable, exchangeable or payable in cash or other products or services. In case of refusal or non-acceptance of the prize or conditions that are inherent to the competition, the prize will not be awarded. In that case, the Brand / Agency Representing Brand / TrueNetwork is entitled to choose another winner. The decision of The Brand / Agency Representing Brand / TrueNetwork is final and no correspondence will be entered into regarding results and the awarding of the prize. 
The winner is able to claim the prize up to 7 days the date of announcement. After this period, entitlement to the prize automatically expires. The Brand / Agency Representing Brand / TrueNetwork  shall be entitled to, without notice, change or modify these terms and conditions specific to the competitions for the duration of the contest.
In the event of any dispute regarding Points not credited to the participant- final result and all miscellaneous matters relating to a campaign present in SPECIAL MENU category, the decision of the Brand/Agency conducting the contest on App shall be final. No correspondence shall be entertained. 
</p>
      </div>
      <h2 className="text_bg redd">
      Privacy Notice:
      </h2>
      <div className="htP_text">
        <p>User/Entrants agree that personal data collected by TrueNetwork  and submitted during Registration, including name, mailing address, phone number, email address, operator details, Location etc may be collected, processed, stored and otherwise used for the purposes of conducting and administering the Contest or suggesting new contests through notification.
With regard to any material submitted by a User for participation in any contest on TrueNetwork , TrueNetwork  Reserves the right to use said material/images/information about the Winner/Participant, to mention/link Tag/Share/Advertise, in all types of Media, where TrueNetwork is promoted.
Registration Conditions: While registering as a member, all the personal information provided by you should be accurate and up to date. If you enter any false information, you are liable to be disqualified once your misrepresentation is detected and proved. Further, your registration as a member shall be deemed to be your acceptance to receive promotional messages from TrueNetwork or any third party so authorized by it. 
</p>
      </div>
      <h2 className="text_bg redd">No Recourse to Judicial or Other Procedures:</h2>
      <div className="htP_text">
        <p>
        To the extent permitted by law, the rights to litigate, to seek injunctive relief or to any other recourse of judicial or any other procedure in case of disputes or claims resulting from or in connection with the contest are hereby excluded, and you have acknowledged, agreed, and expressly waive any and all such rights.
        </p>
      </div>
      <h2 className="text_bg redd">Limitation of Liability:</h2>
      <div className="htP_text">
        <p>
        To the maximum extent permitted by law, you agree to indemnify and keep indemnified TrueNetwork at all times from and against all liability, claims, demands, losses, damages, costs and expenses resulting from any act, default or omissions by you and or a breach of any warranty by you detailed herein to the maximum extent permitted by law. You indemnify and agree to keep TrueNetwork indemnified, at all times, from and against any liability, actions, claims, demands, losses, damages costs and expenses for or in respect of which TrueNetwork will or may become liable by reason of or related or incidental to any act, default or omission by you under these rules including without limitations resulting from or in relation to any breach, non-observance, act or omission whether negligent or otherwise, pursuant to those rules by you. To the maximum extent permitted by law, you agree to hold TrueNetwork, its respective Directors, Officers, Employees and Assignees, harmless for any injury or damage caused or claimed to be caused by participation in the Competition/game and/or use or acceptance of any prize won.
In any manner, website and company's management shall not be responsible for any damage to or loss of any lost opportunity, any technical interruption and/or any other related problems, which is beyond the company's controls. 
TrueNetwork shall not be liable for any loss or damage of any nature whatsoever in connection with the contest.
        </p>
      </div>
      <h2 className="text_bg redd">Contest Creation Policies:</h2>
      <div className="htP_text">
        <p>
        Overview: Our Contest creation policies provide guidance on what type of content are allowed. When user creates a contest, each contest is liable to be reviewed against these policies. Contest shall be liable to be rejected, without any notice, if at any time, it violates any of these policies.
Please read the below instructions carefully before creating any type of contest:
•	Content (Text, Image, Audio or video) must not include any inappropriate, illegal or unsafe words or messaging.
•	Quiz that contain adult content, Drug & alcohol use, profanity (socially offensivelanguage) or Violence will be disapproved.
•	Heavy images or audios may result in disapproval of the contest.
•	We reserve the right to reject, approve or remove any contest for any reason, at our sole discretion including contests that negatively affect our relationship with our users/partner/third party or adversely affect the image of TrueNetwork.
•	We may also block a user if the user creates contests against our policies.
•	These policies are subject to modification/revision/change at any time without notice.

        </p>
      </div>
      <h2 className="text_bg redd">USER CONDUCT AND CONTENT</h2>
      <div className="htP_text">
        <p>
        You must comply with the laws that apply to you in the location that you access the Services from. If any laws applicable to you restrict or prohibit you from accessing Services, you must comply with those legal restrictions or, if applicable, stop accessing the Services.
You agree that, to the best of your knowledge, all the information you provide to us on accessing the Services is and shall remain true, accurate and complete at all times.Information, data, software, sounds, photos, video tags, or other materials (Content) may be sent, uploaded, communicated, transmitted or otherwise made available via Services by you or by others. You should therefore only share Content that you are comfortable sharing publicly. You understand and agree that all Content that you may be sent when using our Services, whether publicly posted or privately sent, is the sole responsibility of the person that sent the Content. This means that you, not us, are entirely responsible for all Content that you may upload, communicate, transmit or otherwise make available via our Services.
You agree that you will not:
•	Use Services to harm anyone or to cause offence to harass any person;
•	Create more than one account to use Services
•	Use another person or entity’s email address in order to sign up to use Services
•	Use Services for fraudulent or abusive purposes including, without limitation, by using our forum to impersonate any person or entity, or otherwise misrepresent your affiliation with a person, entity, our forum or our games;
•	Disguise, anonymise or hide your IP address or the source of any Content that you may upload;
•	Use the Services for any commercial use, or to advantage third parties or facilitate the sending of any advertisement or spam;
•	Remove or amend any proprietary notices or other ownership information from the Services;
•	interfere with or disrupt Services or servers or networks that provide Services;
•	attempt to decompile, reverse engineer, disassemble or hack the Services, or to defeat or overcome any of the encryption technologies or security measures or data transmitted, processed or stored by TrueNetwork;
•	‘harvest’, ‘scrape’ or collect any information about or regarding other people that use the Services, including, but not limited to any personal data or information (including by uploading anything that collects information including but not limited to ‘pixel tags’ cookies, graphics interchange formats (‘gifs’) or similar items that are sometimes also referred to as ‘spyware’;
•	sell, transfer or try to sell or transfer an account with us or any part of an account;
•	interfere with the ability of others to enjoy using the Services;
•	disobey any requirements or regulations of any networks that may be connected to Services;
•	use Services in violation of any applicable law or regulation;
•	use, or encourage, promote, facilitate or instruct others to use the Services for any illegal, harmful or offensive use;
•	Use the Services in any other way not permitted by these terms.
•	Use Services to harm anyone or to cause offence to harass any person;
•	Create more than one account to use Services
•	Use another person or entity’s email address in order to sign up to use Services
•	Use Services for fraudulent or abusive purposes including, without limitation, by using our forum to impersonate any person or entity, or otherwise misrepresent your affiliation with a person, entity, our forum or our games;
•	Disguise, anonymise or hide your IP address or the source of any Content that you may upload;
•	Use the Services for any commercial use, or to advantage third parties or facilitate the sending of any advertisement or spam;
•	Remove or amend any proprietary notices or other ownership information from the Services;
•	interfere with or disrupt Services or servers or networks that provide Services;
•	attempt to decompile, reverse engineer, disassemble or hack the Services, or to defeat or overcome any of the encryption technologies or security measures or data transmitted, processed or stored by TrueNetwork;
•	‘harvest’, ‘scrape’ or collect any information about or regarding other people that use the Services, including, but not limited to any personal data or information (including by uploading anything that collects information including but not limited to ‘pixel tags’ cookies, graphics interchange formats (‘gifs’) or similar items that are sometimes also referred to as ‘spyware’;
•	sell, transfer or try to sell or transfer an account with us or any part of an account;
•	interfere with the ability of others to enjoy using the Services;
•	disobey any requirements or regulations of any networks that may be connected to Services;
•	use Services in violation of any applicable law or regulation;
•	use, or encourage, promote, facilitate or instruct others to use the Services for any illegal, harmful or offensive use;
•	Use the Services in any other way not permitted by these terms.

        </p>
      </div>
      <h2 className="text_bg redd">General Conditions:</h2>
      <div className="htP_text">
        <p>
        TrueNetwork is not responsible for any typographical or other error in printing of the offer, administration of the Contests, or in the announcement of the prizes. TrueNetwork 's decision is final and binding and no correspondence will be entered into in relation to such decision. TrueNetwork reserves the right to disqualify any entry or entrant that violates the Competition rules or that adversely affects the fairness or the legitimate operation of the Contests. There is no cash alternative and the prizes must be taken as offered. TrueNetwork reserves the right to substitute prize(s) of an equivalent value should the prizes become unavailable for any reason. If any winner is not able (for whatever reason) to accept their prize then TrueNetwork reserves the right to award the prize to another participant. Potential prize winners may be required to provide any additional information that may be required by TrueNetwork Failure on part of the winners of the contest to provide such information shall entitle TrueNetwork, at its, Discretion, to disqualify the winner, cancel and forfeit the prize and select an alternate winner. The Competition is governed by the laws of Seychelles. Seychelles courts shall have jurisdiction over any dispute arising in relation thereto. In the event of an inconsistency in interpretation between the English language and Hindi language versions of the Terms and Conditions, the English language version shall prevail.
Rights: TrueNetwork reserves the right to cancel, discontinue or permanently withdraw the contests or any part of it at any time at its discretion and before or prior to the period indicated on the website or its marketing communications, and without any prior notice given to any member for whatsoever reason it may be. 
Further, company will not be responsible for any issue related to contest participated on our website/application. Any query on any question and its answers will not be accepted by TrueNetwork In event of any dispute regarding Questions and their answers in the contests, TrueNetwork shall not be liable in any manner whatsoever. Any chance of any fault, misunderstanding or dispute concerning any part of the participation, declaration of the winners of the quizzes, the operation of the services, correctness or incorrectness of questions and answers and/or the validity of any question or answer options, the decision of the company shall be final and binding on all the members. Further, the company is allowed to take decisions against any matter which may or may not be mentioned in the concerned Terms and Conditions.
Early Termination or Extension of Winner Declaration date: TrueNetwork may change the dates of declaration of results for any contest based on the number of participants. TrueNetwork may conduct or declare results before the due date if it receives the maximum number of participations or may delay it until it gets the minimum entries.
Force Majeure: Any activity of the website like delivery of coupons or goods, participation of quizzes or announcement of any winner may be postponed or referred ahead if any obstacles like company's personal situation, happening of any unnatural and unusual event or an event of any force majeure occurs. In such a situation, company will try to take the best possible decisions at that time.
To the maximum permitted under law, under no circumstances whatsoever will TrueNetwork be responsible or liable to you or to any other entity, even if advised of the possibility of such damages, under any legal theory, whether contract, tort of otherwise, for any direct, compensatory, indirect, incidental, consequential, including but not limited to, any lost profits and lost business opportunities, business interruption, revenue, income, goodwill, use, data or other intangible losses, special, exemplary or punitive damages that result from or relate in any manner whatsoever, to your use of the Services, or reliance of any Services, or to any errors, inaccuracies, omissions, defects, security breaches, or any other failure to perform by the Services. Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations may not apply to you.
You acknowledge that all copyright, trademarks, and other intellectual property rights in and relating to the Services are owned by or licensed to TrueNetwork. You agree not to use the Services, or any elements thereof, for anything else. These terms also apply to any update or patches which we may release or make available for any of the Services and any such update or patch shall be deemed part of the Services for the purposes of these terms. You acknowledge and agree that, you shall have no ownership or property interest in any of our online account, any Points.  You must not copy, distribute, make available to the public or create any derivative work from our Services or any part of our Services unless we have first agreed to this in writing. In particular, you must not make use or available any cheats or technological measures designed to control access to, or elements of, our Services, including providing access to any Points, whether on a free of charge basis or otherwise. By submitting Content via the Services, you are representing that you are fully entitled to do so and:
•	Grant TrueNetwork the right to edit, adapt, publish and use your entry and any derivative works we may create from it, in any and all media, for any purpose, in perpetuity, without any payment to you;
•	Acknowledge that you may have all rights in the Content, for example the right to be named as the creator of your entry and the right not to have work subjected to derogatory treatment.
•	You agree that we have no obligation to monitor or protect your rights in any Content that you may submit to us; but
•	You do give TrueNetwork the right to enforce your rights in that Content if we want to, including but not limited to taking legal action (at our cost) on your behalf.
You must not copy, distribute, make available to the public or create any derivative work from the Services. If you believe that someone else has infringed your intellectual property rights over the internet, you may contact us at hello@mchamp.com.
We do not guarantee that any of our Services will be available at all times or at any given time or that we will continue to offer all or any of our Services for any particular length of time. We have the right to withdraw or modify our Services (in whole or in part) without notice but there may also be times when our Services or any part of them are not available for technical or maintenance related reasons, whether on a scheduled or unscheduled basis, or to allow us to improve user experience, or because it no longer makes business sense for us to provide the relevant part of the Services.
Notwithstanding anything to the contrary, you acknowledge and agree that any or all of our services may be terminated in whole or in part at our sole discretion. You assume any and all risk of loss associated with the termination of your services.We do not guarantee that any of our Services will be available or error-free at all times or at any given time. We may change and update our Services in whole or in part without notice to you. We are not liable or responsible for any failure to perform, or delay in performance of, any of our obligations that is caused by events outside our reasonable control.

        </p>
      </div>
      <h2 className="text_bg redd">Internet:</h2>
      <div className="htP_text">
        <p>
        TrueNetwork shall not be responsible for electronic transmission errors resulting in omission, interruption, deletion, defect, delay in operations or transmission. TrueNetwork shall not be responsible for theft or destruction or unauthorized access to or alterations of Contest materials, or for technical, network, telephone equipment, electronic, computer, hardware or software malfunctions or limitations of any kind. TrueNetwork shall not be responsible for inaccurate transmissions of or failure to display Contest materials on account of technical problems or traffic congestion on the Internet or at any website or any combination thereof. If for any reason the Internet portion of the Contests is not capable of running as planned, including infection by computer virus, bugs, tampering, unauthorized intervention, fraud, technical failures, or any other causes which corrupt or affect the administration, security, fairness, integrity, or proper conduct of this Competition, TrueNetwork reserves the right at its sole discretion to cancel, terminate, modify or suspend the Competition. TrueNetwork shall not be responsible for any claims arising out of any such technical failures before, during or after the contest.

        </p>
      </div>
      <h2 className="text_bg redd">Arbitration:</h2>
      <div className="htP_text">
        <p>
        Seychelles Laws shall govern these 'Terms and Conditions' excluding its conflict-of-laws principles. If any dispute is not resolve amicably by the parties to the dispute within 30 days after arising of the dispute, then the Arbitration and Conciliation Act, 1996, including any rules made thereunder, shall be followed and the decision announced by the arbitral tribunal shall be final and binding on all the parties.
With the help of a common agreement, one arbitrator will be appointed. After that, a group of three arbitrators will be formed by each party. If parties cannot reach an agreement through a single arbitrator, two arbitrators will choose the third one. The arbitration will be conducted in English at Seychelles. Every party has the rights to appeal for any judicial relief in any court having jurisdiction against their rights until dispute is resolved.
These 'Terms and Conditions' include a set of remedies and limitations, which are subject to an order of injunctive or equitable relief passed by the arbitral tribunal. The existence of any arbitral proceedings and information disclosed in the course of proceedings will be considered as 'confidential information' However, the parties involved hold the right to disclose such information under confidentiality restrictions to the appropriate Court. 

        </p>
      </div>
      <h2 className="text_bg redd">Jurisdiction</h2>
      <div className="htP_text">
        <p>
        These Terms and Conditions are subject to Seychelles laws and the Courts at Seychelles shall have the exclusive jurisdiction in respect of any disputes or any matter arising here from these Terms.
        </p>
      </div>
      <h2 className="text_bg redd">WAIVERS OF OUR RIGHTS</h2>
      <div className="htP_text">
        <p>
        Our failure to exercise or enforce any of our rights under these terms does not waive our right to enforce such right. Any waiver of such rights shall only be effective if it is in writing and signed by us.
        </p>
      </div>
      <h2 className="text_bg redd">COMPLAINTS AND DISPUTE RESOLUTION</h2>
      <div className="htP_text">
        <p>
        Most concerns can be solved quickly by contacting us at hello@mchamp.com </p>
      </div>
      <h2 className="text_bg redd">QUESTIONS ABOUT THESE TERMS AND CONDITIONS</h2>
      <div className="htP_text">
        <p>
        If you have any questions about these terms or about the Services, please, contact us to the following mail address:hello@mchamp.com
These terms were last updated on 23/08/2024.
 </p>
      </div>
    </div>
    <QuizFooter></QuizFooter>
  </div>
  <Right></Right>
</div>


   </>
  )
}

export default TermCondition
