import React, { useEffect, useState } from 'react'
import loader from '../../images/loader.gif'
import { BASE_URL} from '../AllApi/CommonUrl';
import { USerRegister } from '../AllApi/Registration';
import Restricted from "../Progress/Restricted";
import { useNavigate, useParams } from 'react-router-dom';
const UserRegister = () => {
  // alert('alert')
      const [load, Setload] = useState(true);
      const {gid}=useParams()
      const navigate = useNavigate();
      const [Restrict, SeRestrict] = useState(false);
      const generateRandomId = async() => {
        if (window && window.ethereum) {
          if (window.ethereum.isMiniPay) {
            let accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
              params: [],
            });
             return accounts[0];
          }
          }
          // return '0x7071E17A089Bc6cED07BBAAd75887A318061AVDHESH1232323AD223'
      };
      useEffect(() => {
        ; (async () => {
          const address = await generateRandomId()
          if(!address){
            navigate('/restricted')
          }
          try {
              const Res = await USerRegister(BASE_URL,address);
                if(Res.status==1){
               navigate(`/GameDetails/${gid}/2`)
              }  
          }catch (error) {
            console.log(error)
          }
        })()
      }, []);
  return (
  <>
      {Restrict&&<Restricted></Restricted>}
   {load &&!Restrict&& <div className='center_vh'>
              <img  src={loader} alt="loader"></img>
            </div>
            }
  </>
  )
}

export default UserRegister
