import React from 'react'
import { useState, useEffect } from 'react';
import double from '../../images/2X.svg'
const WonBreak = (props) => {
 
  // useEffect(() => {
  //   ; (async () => {
  //     try {
  //       if (!user_id) {
  //         navigate(`/Register/${contest}`);
  //       }
  //       const gameendpoint = 'goldgame/gameDetail';
  //       const gamedetail = await Details(contest,user_id, BASE_URL, gameendpoint,tok);
  //       setwinstatus(gamedetail.winning_status)
  //       Setwinning_message(gamedetail.winning_message)
  //       setWinn(gamedetail.result.winbreakup)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, []);
    return (
    <>
      <div id="test-swipe-1" className="col s12">
      <p className="game_ttl">Winnings Breakup</p>
        <div className="">
        <div className="new_msg">
          <img src={double} alt={double} className='new_msg_img'/>
          <p class="game_ttl">Every winner will get 2 Diamonds (0.02 USDT)</p>
        </div>
    </div>
{/* {winn!=null &&winstatus==1&&
  winn.map((key,index) => {
    return(
      <div className="ldr_list" key={index}>
      <div className="ldr_itm">
        <div className="ldr_sb_itm">
        <p>#{key.rank}</p>
        </div>
        <div className="ldr_sb_itm">
        <p>
          <img src={dd} alt="Daimond" /> {key.winning}
        </p>
        </div>
      </div>
    </div>
    )
  })
}  */}
      </div>

    </>
  )
}

export default WonBreak
