import QuizInfo from "./components/routes/QuizInfo";
import TournamentRoute from "./components/routes/TournamentRoute";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import "materialize-css/dist/css/materialize.min.css";
// import "materialize-css/dist/js/materialize.min.js";
// import M from "materialize-css";
import QuestionScreenRoute from "./components/routes/QuestionScreenRoute";
import ProgressRoute from "./components/routes/ProgressRoute";
import GameRoute from "./components/routes/GameRoute";
import GameDetails from "./components/Game/GameDetails";
import GameLevel from "./components/Game/GameLevel";
import GamePlay from "./components/Game/GamePlay";
import History from "./components/Progress/RedeemHist"
import Error from "./components/Error";
import Restricted from "./components/Progress/Restricted";
import HowPlay from "./components/Game/HowPlay";
import FAQ from "./components/Game/FAQ";
import TermCondition from "./components/Game/TermCondition";
import { useState, useEffect } from 'react'
import Privacy from "./components/Game/Privacy";
import Home from "./components/routes/Home";
import UserProfileRoute from "./components/routes/UserProfileRoute";
import EditProfile from "./components/User/EditProfile";
import Video from "./components/Progress/Video";
import Form from "./components/User/Form";
import PayZero from "./components/Progress/PayZero";
import UserRegister from "./components/User/UserRegister";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
<Home></Home>
    ),
  },
  {
    path: "/gameroute/:fg",
    element: (
    <GameRoute></GameRoute>
    ),
  },
  {
    path: "/QuizInfo/:id",
    element: (
   <QuizInfo></QuizInfo>
    ),
  },
  {
    path: "/Form",
    element: (
   <Form></Form>
    ),
  },
{
  path: "/Screen",
  element: (
 <QuestionScreenRoute></QuestionScreenRoute>
  ),
},
{path: "/progress/:flag",
element: (
  <ProgressRoute></ProgressRoute>
),
},
{
  path: "/Play/:id",
  element: (
<QuestionScreenRoute></QuestionScreenRoute>
  ),
},
{
  path: "/Game/:id",
  element: (
 <GameRoute></GameRoute>
  ),
},
{
  path: "/GameDetails/:contest",
  element: (
   <GameDetails></GameDetails>
  ),
},


{
  path: "/GameDetails/:contest/:flag",
  element: (
   <GameDetails></GameDetails>
  ),
},
{
  path: "/GameLevel/:constid",
  element: (
 <GameLevel></GameLevel>
  ),
},
{
  path: "/Profile",
  element: (
<UserProfileRoute></UserProfileRoute>
  ),
},
{
  path: "/Edit",
  element: (
    <EditProfile></EditProfile>
  ),
},
{
  path: "/video",
  element:(
    <Video></Video>
  ),
},
{
  path: "/GamePlay/:user_id/:contest_id/:level_id/:score/:time",
  element: (
<GamePlay></GamePlay>
  ),
},
{
  path: "/GamePlay/:user_id/:contest_id/:level_id/:score",
  element: (
<GamePlay></GamePlay>
  ),
},
{
  path: "/GamePlay/:user_id/:contest_id/:level_id/:score/:time/:quiz",
  element: (
<GamePlay></GamePlay>
  ),
},
{
  path: "/history",
  element: (
<History></History>
  ),
},
{
  path: "/restricted",
  element: (
  <Restricted></Restricted>
  ),
},
{
  path: "/howplay",
  element: (
<HowPlay></HowPlay>
  ),
},
{
  path: "/faq",
  element: (
<FAQ></FAQ>
  ),
},
{
  path: "/term",
  element: (
<TermCondition></TermCondition>
  ),
},
{
  path: "/policy",
  element: (
<Privacy></Privacy>
  ),
},
{
  path: "*",
  element: (
<Error></Error>
  ),
},
{
  path: "/Checkin/:flag",
  element: (
<PayZero></PayZero>
  ),
},
{
  path: "/Checkin/:flag/:contest",
  element: (
<PayZero></PayZero>
  ),
},

{
  path: "/Register/:gid",
  element: (
    <UserRegister></UserRegister>
  ),
},

]);
function App() {
  // useEffect(() => {
  //   M.AutoInit(); // Initializes Materialize components
  // }, []);
  return (
   <>
    <RouterProvider router={router} />
   
   </>
  );
}

export default App;
