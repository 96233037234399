import React from 'react'
import play from '../../images/free_play.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
const FreePlay = (props) => {
    const url = window.location.href.split('/')[2];
    function Paygame(gameurl) {
        // var link =`http://testing2.mchamplite.com/knifefling/?userId=${9}&contestId=${3}&levelNameId=${5}&newurl=http://localhost:3000/GamePlay`
        var link = `${gameurl}?newURL=https://${url}`
        window.location.href = link;
    }
  return (
    <>

    <div className='free_play'>
        <img  src={play} alt="free_play"></img>
    </div>
    <p class="l_infogam ft_12">This mode is only for the practices. It has no connection with the Tournament section. Please note that after completing the game in this section you will not get diamond.</p>
    <div class="d_flex2 mt_10">


    <Swiper
        slidesPerView={2.2}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2.2,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 2.2,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 2.2,
            spaceBetween: 5,
          },
        }}
        
        className="mySwiper"
      >
       {props.arr!=null &&
                  props.arr.map((data, index) => { 
        return( <>
        <SwiperSlide>
        <div class=" game3 category2" onClick={() => Paygame(data.game_demo_url)}>
            <img src={data.icon_image_white}alt="Celebrations" class="icon_sm"/>
            <p class="font_md">{data.contest_name
            }</p>
            <button class="btn_white btn_gren pulsee btn_slide">Play Now</button>
        </div>
        </SwiperSlide>
                </>     
                )
            })
          }
    </Swiper>
    </div>
    </>
  )
}

export default FreePlay
