import React from 'react'
import Right from '../header/Right'
import loader from '../../images/loader.gif'
import CateHeader from '../header/CateHeader'
import edit from '../../images/pencil.png'
import users from '../../images/play_solo.png'
import { BASE_URL,user_id,tok} from '../AllApi/CommonUrl';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
const EditProfile = () => {
    const [isLoading, setIsLoading] = useState(false);
  const {state}=useLocation()
  const name =state.name
  const picture=state.pic
  const user_id = Cookies.get('userId')
  const tok = typeof (Cookies.get('logintoken')) === "undefined" || Cookies.get('logintoken') == "" ? Cookies.get('idtoken') : Cookies.get('logintoken')
  const [selectedImage, setSelectedImage] = useState(''); // State to store the selected image
  const [profilePic, setProfilePic] = useState(null); //
  const [UserName, SetUserName] = useState('');
  const [show, SetShow] = useState(false);
  const [fill, Setfill] = useState(false);
  const nav = useNavigate()
  const [errors, setErrors] = useState({});
  const handleImageChange = (e) => {
    Setfill(true);
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file)
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePic(reader.result); // Set the image URL for preview
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };


  const handleUploadClick = () => {
    document.getElementById("fileInput").click(); // Simulate file input click
  };
  const back = () => {
    nav('/Profile')
  };
  const SuccessHandler = () => {
    Swal.fire({
      title: "Updated  Successfull!",
      icon: "success",
      allowOutsideClick: false,
      showConfirmButton: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        nav('/Profile')
      }
    });
  }
  // form submittion data
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm({UserName,selectedImage});
    setErrors(newErrors);
    const formData = new FormData();
    formData.append('user_image',(!selectedImage)?picture:selectedImage);
    formData.append('user_name', (!UserName)?name:UserName);
    formData.append('user_id', user_id);
    const Idtoken =`${tok}`;
    if (Object.keys(newErrors).length<=1 &&fill) {
      setIsLoading(true)
      SetShow(true)
      try {
        // Send form data to server using axios or fetch
        const response = await axios.post(`${BASE_URL}/Userheaderdetail/userProfileEdit/`, formData, {
          headers: {
            'Content-Type': "multipart/form-data",
            'Idtoken': `${Idtoken}`, // Important for file uploads
          },
        });
        if (response.data.status == 1) {
          SuccessHandler()
        }
        console.log(response.data.message)
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    } else {
      console.log('Form submission failed due to validation errors.');
  }
    };
    const validateForm = (data) => {
      const errors = {};
      if (!data.UserName.trim()) {
        errors.UserName = 'Username is required';
      } else if (data.UserName.length < 4) {
        errors.UserName = 'Username must be at least 4 characters long';
      }
      else if(data.UserName.indexOf(" ") !== -1){
        errors.UserName = 'Space not Allowed';
      }
      if (!data.selectedImage) {
        errors.image = 'Image is required';
      } 
      // if (!data.email.trim()) {
      //     errors.email = 'Email is required';
      // } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      //     errors.email = 'Email is invalid';
      // }
      // else if(data.email.indexOf(" ") !== -1){
      //   errors.email = 'Space not Allowed';
      // }
      return errors;
    };
    console.log('sdfrewj', errors.image)
    return (
      <>
        <div className="section">
          <div className="s_left">
            <CateHeader name={'Edit Profile'} path={'/Profile'}></CateHeader>
            {isLoading && <div className='center_vh'>
              <img  src={loader} alt="loader"></img>
            </div>
            }{!isLoading&&
            <form onSubmit={handleSubmit}>
              <div className='edit_pro'>
                <div class="small-12 medium-2 large-2 columns">
                  <div class="circle22">
                    <img class="profile-pic" src={profilePic == null ? `${picture}` : `${profilePic}`} onClick={handleUploadClick}></img>
                  </div>
                  
                  <div class="p-image">
                    <img class="fa fa-camera upload-button" src={edit} onClick={handleUploadClick}></img>
                    <input class="file-upload" type="file" accept="image/*" id="fileInput" onChange={handleImageChange} />
                  </div>
                </div>

                {!fill&&errors.image && (
                    <span className="error-message err_2">
                      {errors.image}
                    </span>
                )}
                <input type='name' placeholder={name} className='ipnut_name' value={UserName}
                  onChange={(e) => SetUserName(e.target.value,Setfill(true))} ></input>
                {errors.UserName && (
                  <span className="error-message">
                    {errors.UserName}
                  </span>
                )}
                <div class="toggle_btn w_100 tg_2">
                  <button class="" onClick={back}>Cancel</button>
                  {show&&<button class="act_btn disable" type="button">Save Changes</button>
                  }
                   {!show&&<button class="act_btn " type="submit">Save Changes</button>
                  }
             </div>

              </div>
            </form>}
          </div>

          <Right></Right>
        </div>
      </>
    )
  }
  export default EditProfile
