import React from 'react'
import GameRoute from './GameRoute'
import pop from '../../images/pop_up.jpg'
import cross from '../../images/cross.png'
import { useState, useEffect } from 'react'
import { checkAndHandleUserId } from '../AllApi/CommonUrl';
import { useNavigate } from 'react-router-dom';
const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ; (async () => {
      checkAndHandleUserId(navigate);
      //     const elem = document.getElementById('modal1');
      // const instance = window.M.Modal.init(elem, { dismissible: false });
      // instance.open();
    })()
  }, []);
  return (
    <>
      
      <GameRoute></GameRoute>
    </>
  )
}

export default Home
