
// const BASE_URL = 'http://fantasyapi.mchamp.com/';
export const gameData = async (constest,user_id,address,BASE_URL,endpoint,tok) => {
    try {
      const Url = `${BASE_URL}/${endpoint}`;
    const Idtoken =`${tok}`;
    const rawData = {
         "user_id":user_id,
         "contest_id":constest,
          "user_address":address   }
    const response = await fetch(Url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Idtoken': `${Idtoken}`,
      },
      body: JSON.stringify(rawData),
    });
    if (!response.ok) {
      throw new Error('some error');
    }
    const jsonData = await response.json();  
    console.log(jsonData)
    return jsonData;
        } catch (error) {
      console.error('Error fetching data:', error);
      return 0;
    }
  };
  