import React from 'react'
import img1 from '../../images/1.jpg'
import img2 from '../../images/2.jpg'
import img3 from '../../images/3.jpg'
import { SelfUser } from '../AllApi/SelfUserApi';
import { BASE_URL } from '../AllApi/CommonUrl';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { checkAndHandleUserId } from '../AllApi/CommonUrl';
const LeadBoard = (props) => {
  // const [lead, setLead] = useState([]);
  const [flag, setFlag] = useState('');
  const [name, setName] = useState('');
  const [pic,Setpic] = useState('');
  const navigate = useNavigate()
  const contest = props.contest
  const [you, setYou] = useState([]);
  const user_id = Cookies.get('userId')
  const tok = typeof (Cookies.get('logintoken')) === "undefined" || Cookies.get('logintoken') == "" ? Cookies.get('idtoken') : Cookies.get('logintoken')
  const Internet=()=>{
    Swal.fire({
      title: "Network issue",
      text:"Weak  internet connection",
      allowOutsideClick: false,
      icon:'error'
    });
  }
  useEffect(() => {
    ; (async () => {
      try {
        if (!user_id) {
          navigate("/");
        }
        // const EndPoint = 'offer/contestWiseLeaderBoard'
        // const leader = await LeaderData(contest, user_id, BASE_URL, EndPoint, tok);
        // setLead(leader.result)
        // console.log('leader b', leader.result)
        const UseEnd = 'userheaderdetail/selfUserPlayed/'
        const SelfUserData = await SelfUser(user_id, BASE_URL, contest, UseEnd, 1, tok);
        console.log('leader',SelfUserData)
        if(SelfUserData==0){
          Internet();
         }
        if (SelfUserData.status == 0) {
          checkAndHandleUserId(navigate);
          }
        setYou(SelfUserData.result)
        setFlag(SelfUserData.status)
        setName(SelfUserData.profile.user_name)
        Setpic(SelfUserData.profile.user_image)
      } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  function formatContent(content) {
    const cleanedContent = content.replace(/[^a-zA-Z0-9 @.]/g, "");
    return cleanedContent.length > 10? cleanedContent.slice(12) : cleanedContent;
}
  return (
    <>
      {
        <div id="test-swipe-3" className="col s12 ">
          <p className="text_detl">Leaderboard will be updated every 2hrs.</p>
          <div className="ldr_list bg_none">
            {you != 0 && <div className="ldr_itm">
              <div className='ldr_new'>
                <div className="ldr_sb_itm">
                  <p>Rank</p>
                </div>
                <div className="ldr_sb_itm">
                  <p>Username</p>
                </div>

              </div>
              <div className="ldr_itm">
                <p>
                  <span>Score</span>
                </p>
              </div>
            </div>}
          </div>

          <div className="leaderboard_section">
            {/* {
            props.lead ==0 &&
            <p className="text_detl">LeaderBoard updating</p>
          } */}
            {flag!=0&&you != 0 && you.level == 5 &&
              <div class="ldr_update you_win">
                <div className='lrd_lft_u'>
                  <div className='ldr_u_rnk'>
                    <p className='rnk_u '>{you.rank}</p>
                    {pic&&<img src={pic} alt={pic} className='img_ldr_usr'/>}
                    {!pic&&<img src={img1} alt={img1} className='img_ldr_usr'/>}
                  </div>
                  {!name && <p>You</p>}
                      {name && <p>{name}</p>}
                </div>
                
                <div>
                <p class="scr_rht">{you.score != 0 && you.score}.0</p>
                </div>
            </div>
            }
            {flag!=0&&you != 0 && you.level == 1 &&
              <div class="ldr_update you_win">
                <div className='lrd_lft_u'>
                  <div className='ldr_u_rnk'>
                    <p className='rnk_u '>.</p>
                    {pic&&<img src={pic} alt={pic} className='img_ldr_usr'/>}
                    {!pic&&<img src={img1} alt={img1} className='img_ldr_usr'/>}
                  </div>
                  {!name && <p>You</p>}
                  {name && <p> {formatContent(name)}</p>}
                </div>
                
                <div>
                <p class="scr_rht">Incompleted</p>
                </div>
            </div>
            }
            {
              props.lead != null &&
              props.lead.map((key, index) => { 
                return (
                  // <div className="ldr_list">
                  //   <div className="ldr_itm">
                  //     <div className='ldr_new'>
                  //       <div className="ldr_sb_itm">
                  //         <h2>#{index + 1}</h2>
                  //       </div>
                  //       <div className="ldr_sb_itm">
                  //         {!key.user_name && <p>{key.android_id}</p>}
                  //         {key.user_name && <p>{key.user_name}</p>}
                  //       </div>
                  //     </div>
                  //     <div className="ldr_itm">
                  //       <p>####</p>
                  //     </div>
                  //   </div>

                  // </div>
                
                  <div class="ldr_update">
                    <div className='lrd_lft_u'>
                      <div className='ldr_u_rnk'>
                        <p className={`rnk_u ${ index+1==1?'rnk_1':index+1==2?'rnk_2':index+1==3?'rnk_3':''}`} >{index + 1}</p>
                        
                        {key.user_image&&<img src={key.user_image} alt={key.user_image} className='img_ldr_usr'/>}
                        {!key.user_image && index+1==1&& <img src={img1} alt={img1} className='img_ldr_usr'/>}
                        {!key.user_image && index+1==2&& <img src={img2} alt={img1} className='img_ldr_usr'/>}
                        {!key.user_image && index+1==3&& <img src={img3} alt={img1} className='img_ldr_usr'/>}
                        {!key.user_image && index+1!=1&&index+1!=2&&index+1!=3&& <img src={img1} alt={img1} className='img_ldr_usr'/>}

                      </div>
                      {!key.user_name && <p>{key.android_id}</p>}
                      {key.user_name && <p>  {formatContent(key.user_name)}</p>}
                    </div>
                    <div>
                      <p class="scr_rht">####</p>
                    </div>
                  </div>

                )
              })
            }
          </div>
        </div>}
    </>
  )
}
export default LeadBoard
