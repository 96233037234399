import React from 'react'
import Right from '../header/Right'
import CateHeader from '../header/CateHeader'
import { NavLink } from 'react-router-dom'
import QuizFooter from '../footer/QuizFooter'
const Privacy = () => {
  return (
    <>
      <div className="section">
        <div className="s_left">
          <div className="header">
            <CateHeader name={'Privacy Policy'} path={'/gameroute/1'}></CateHeader>
          </div>
          {/* how to play content*/}
          <div className="htplay game_det">
            <h2 className="text_bg redd">Introduction</h2>
            <div className="htP_text">
              <p>
                This policy applies to users who visit or access our website  <a href='https://games.mchamp.xyz/'>https://games.mchamp.xyz/</a>(“Website”) or uses our services.
                <br></br>
                <br></br>
                We respect the privacy of it's users and is committed to protect it in all respects. We have drafted this Privacy Policy to explain how we collect and use personal data and we are committed to respect your privacy and to comply with applicable privacy laws. By using this Website, you accept, without limitation or qualification, these terms and condition mentioned in Privacy Policy and Terms & Conditions. The Website may contain links to other companies' websites and services that have privacy policies of their own. We shall not be responsible for the privacy practices of others. As you review our policy, keep in mind that it applies to all our brands, products and services notwithstanding, such brand, product and service do or do not have a separate privacy policy or that is linked to this policy ("Our Services" or "Services.") If you do not agree to the terms of this Privacy policy, please do not use this Website.
                <br></br><br></br>
                You must be 18 years of age or older to utilize the Website in any capacity. By accessing the Content and/or the Website, you affirm that you are at least 18 (eighteen) years of age and that you possess the legal right and capacity to use the Website
              </p>
            </div>
            <h2 className="text_bg redd">
              What you need to do and your confirmation to us?
            </h2>
            <div className="htP_text">
              <p>
                Please read this privacy policy carefully to understand how we handle your personal information. By engaging with us in the ways set out in this privacy policy, including downloading, accessing and using any of our Website, you confirm that you have read and understood the entirety of this privacy policy, as it applies to you.
              </p>
            </div>
            <h2 className="text_bg redd">What kinds of information do we collect?</h2>
            <div className="htP_text">
              <p>
                Depending on Services that you request, we collect information when you choose to provide us with that information. Our primary aim in collecting the information is to provide services that most likely meet your needs.
                <h3 className='text_bg1 redd1'>i.Things you do and information you provide.</h3>
                <p>We do not collect your personal information (example. email id, name etc). We also do not collect information such as how you use our Services, such as the types of data you view or activities you engage with or the frequency and duration of your use of the Services.</p>
              </p>
              <h3 className='text_bg1 redd1'>ii.Device information.</h3>
              <p>We do not collect information about your devices. </p>
              <h3 className='text_bg1 redd1'>iii.Information from third-party partners.</h3>
              <p>We collect, process, use and store your digital wallet address details for processing redemption of Diamond. We receive information about you and your activities on and off from third-party partners, such as information from a partner when we jointly offer services or from an advertiser about your experiences or interactions with them. This information is utilized by the third party, in accordance with its privacy policy. Because we do not control the privacy practices of these third parties, you should evaluate their practices before deciding to use their services.</p>
              <h3 className='text_bg1 redd1'>iv.Cookies and Web Beacons </h3>
              <p>A cookie is a string of information that a website stores on a user's computer, and that the user’s browser provides to the website each time the user returns. We use cookies to test new features and to help us identify and track user, their usage of Website. User who do not wish to have cookies placed on their computers should set their browsers to refuse cookies, but some of Website’s features may not function properly without the aid of cookies.</p>
            </div>
            <h2 className="text_bg redd">
              How do we use this information?
            </h2>
            <div className="htP_text">
              <p>We are passionate about creating engaging and customized experiences for people. We use all of the information we have to help us provide and support our Services.</p>
              <h3 className='text_bg1 redd1'>i.Provide, improve and develop Services.</h3>
              <p>We deliver our Services, personalize content, and make suggestions for you by using this information to understand how you use and interact with our Services and the people or things you're connected to and interested in on and off our Services. We also use information we have to provide shortcuts and suggestions to you. We may analyze the information we must evaluate and improve products and services, develop new products or features, and conduct audits and troubleshooting activities. </p>
              <h3 className='text_bg1 redd1'>ii.Show and measure ads and services.</h3>
              <p>We use the information we have to improve our advertising and measurement systems so we can show you relevant ads on and off our Services and measure the effectiveness and reach of ads and services. You can learn more about advertising on our Services and how you can control information about you is used to personalize the ads you see.</p>
              <h3 className='text_bg1 redd1'>iii.	Other uses of your information</h3>
              <p>We also collect, use and store your information for the following additional reasons:</p>
              <br></br>
              - For internal corporate reporting, business administration, ensuring adequate insurance coverage for our business, ensuring the security of company facilities, research and development, and to identify and implement business efficiencies. We may process your information for these purposes where it is in our legitimate interests to do so;
            <br></br><br></br>
            - To comply with any procedures, laws and regulations which apply to us – this may include where we reasonably consider it is in our legitimate interests or the legitimate interests of others to comply, as well as where we are legally required to do so; and
          <br></br><br></br>
          - To establish, exercise or defend our legal rights – this may include where we reasonably consider it is in our legitimate interests or the legitimate interests of others, as well as where we are legally required to do so.
      </div>
      <h2 className="text_bg redd">
        Legal basis for use of your personal information?
      </h2>
      <div className="htP_text">
        <p>
          We consider that the legal bases for using your information as set out in this privacy policy are as follows:
          <br></br><br></br>
        </p>
        <p> i. Our use of your information is necessary to perform our obligations under any contract with you (for example, to comply with the terms of service of our Websites);</p>
        <br></br>
        <p> ii. You have provided us with your consent to do so. This applies in relation to the advertising and marketing materials delivered via our apps that are tailored to you and your preferences. You can control and withdraw your consent at any time on our apps.</p>
        <br></br>
        <p> iii.	Where (i) or (ii) does not apply, our use of your information is necessary for our legitimate interests or the legitimate interests of others. Our legitimate interests are to:</p>
        <br></br>
        <p>
          - Operate, maintain and improve our Website and our marketing optimisation efforts;
          <br></br><br></br>
          - Understand the way in which our Website are used; and
          <br></br>
          - For internal group administrative purposes.
        </p>

      </div>
      <h2 className="text_bg redd">How is this information shared?</h2>
      <div className="htP_text">
        <p>We understand and value your privacy and We do not share any personal information of user with any third party. We do not share, lease or otherwise disclose any of the information to any third party unless otherwise stated herein.</p>
        <h3 className='text_bg1 redd1'>i. Sharing with affiliates.</h3>
        <p>We share information we have about you with our affiliates or other authorized third parties who process the information for delivery of the services as described in this policy.</p>

        <h3 className='text_bg1 redd1'>ii. New owner.</h3>
        <p>If the ownership or control of all or part of our Services or their assets changes, we may transfer your information to the new owner. We may share your information if we have your consent to do so. Please consider and read carefully before disclosing any data or information.</p>
        <h3 className='text_bg1 redd1'>iii.	Sharing with Third-Party Partners and Customers.</h3>
        <p>We work with third party companies who help us provide and improve our Services or who use advertising or related products, which makes it possible to operate our companies and provide free services to people around the world. This information is utilized by the third party, in accordance with its privacy policy. Because we do not control the privacy practices of these third parties, you should evaluate their practices before deciding to use their services. Here are the types of third parties we may share information with about you:</p>

        <h3 className='text_bg1 redd1'>iv. Advertising, Measurement and Analytics Services (Non-Personally Identifiable Information Only).</h3>
        <p>We want our advertising to be as relevant and interesting as the other information you find on our Services. With this in mind, we use all of the information we have about you to show you relevant ads. We do not share information that personally identifies you (personally identifiable information is information like name or email address that can by itself be used to contact you or identifies who you are) with advertising, measurement, or analytics partners unless you give us permission. We may provide these partners with information about the reach and effectiveness of their advertising without providing information that personally identifies you, or if we have aggregated the information so that it does not personally identify you.</p>
        <h3 className='text_bg1 redd1'>v.	Vendors, service providers and other partners.</h3>
        <p>We transfer information to vendors, service providers, and other partners who globally support our business, such as providing technical infrastructure services, analyzing how our Services are used, measuring the effectiveness of ads and services, providing customer service, facilitating payments, or conducting academic research and surveys.</p>
      </div>
      <h2 className="text_bg redd">
        How long we store your information?
      </h2>
      <div className="htP_text">
        <p>We keep your personal information for no longer than necessary for the purposes for which the information is processed. The length of time for which we retain information depends on the purposes for which we collect and use it and/or as required to comply with applicable laws and to establish, exercise or defend our legal rights.</p>
      </div>
      <h2 className="text_bg redd">
        Data Subject Rights
      </h2>
      <div className="htP_text">
        <p>You have the right to delete, access, update, correct errors in, and export for transfer any of your personal information. We makes these data subject rights available to you regardless of which country you reside in. Managing the subsequent use and disclosure of your personal information.
          As explained throughout this Privacy Policy, we do not collect, store or process any of your personal information.
        </p>
      </div>
      <h2 className="text_bg redd">Children’s Privacy.</h2>
      <div className="htP_text">
        <p>
          These Services do not address anyone under the age of 18. We do not knowingly collect personally identifiable information from children under 18. In the case we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
        </p>
      </div>
      <h2 className="text_bg redd">Risks and how we keep your personal information secure.</h2>
      <div className="htP_text">
        <p>
          We are committed to taking reasonable efforts to secure the information that you choose to provide us, and we use a variety of security technologies and procedures to help protect against unauthorized access to or alteration, disclosure, or destruction of Personal Data. Unfortunately, no transmission of Personal Data over the Internet can be guaranteed to be 100% secure. Accordingly, and despite our efforts, we cannot guarantee or warrant the security of any information you transmit to us, or to or from our online products or services. we have no responsibility or liability for the security of information transmitted via the Internet.
        </p>
      </div>
      <h2 className="text_bg redd">Intellectual Property Rights.</h2>
      <div className="htP_text">
        <p>
          All Content on the Website is protected by Copyright. We, own copyright and/or database rights in the selection, coordination, arrangement and enhancement of such content, as well as in the original content.
          <br></br><br></br>
          Except as expressly set out in herein, nothing shall give you any right in respect of any intellectual property owned by us. You shall not assert or attempt to obtain any such right and you shall not use, copy, reproduce, modify, publish, transmit, participate in the transfer or sale of, create derivative work from, or in any way exploit, Our Name, Trademarks, logos or other proprietary marks, or any of the content of the site, in whole or in any part, except as provided herein.
          <br></br><br></br>
          Except as otherwise expressly permitted under copyright law, no copying, redistribution, retransmission, publication or commercial exploitation of downloaded material will be permitted without our express permission and that of the Copyright Owner. In the event of any permitted Copying, redistribution or publication of copyright material, no changes in or deletion of author attribution, Trademark legend or Copyright Notice shall be made. You acknowledge that you do not acquire any ownership right by downloading copyright material.
        </p>
      </div>
      <h2 className="text_bg redd">How will we notify you of changes to this policy?</h2>
      <div className="htP_text">
        <p>
          By using our services, you consent to the collection, use, and disclosure of information and to the terms of this policy. We may time to time modify this policy, we will post the changes on this page so that you are always aware of the latest version of Policy. If we make any material changes, we will notify you before we make such changes to this policy and give you the opportunity to review on the revised policy before continuing to use our Services.
        </p>
      </div>
      <h2 className="text_bg redd">Comments and Questions.</h2>
      <div className="htP_text">
        <p>
          If you have any comments or questions about our privacy practices, please contact us at <a href="mailto:hello@mchamp.com">hello@mchamp.com.</a>
          <br></br><br></br>
          Date of Last Revision: 10/02/2025
        </p>
      </div>
    </div >
      <QuizFooter></QuizFooter>
        </div >
  <Right></Right>
      </div >
    </>
  )
}

export default Privacy
