import React from 'react'
import GameRoute from './GameRoute'
import { useState, useEffect } from 'react'
import { BASE_URL} from '../AllApi/CommonUrl';
import { USerRegister } from '../AllApi/Registration';
import Restricted from "../Progress/Restricted";
import Cookies from 'js-cookie';
const Home = () => {
  const [load, Setload] = useState(true);
  const [Restrict, SeRestrict] = useState(false);
const user_id = Cookies.get('userId')
  const generateRandomId = async() => {
    if (window && window.ethereum) {
      if (window.ethereum.isMiniPay) {
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [],
        });
         return accounts[0];
      }
      }
      // return '00x7071E17A089Bc6cED07BBAAd75887A318061AVDHESH1232323AD223'
  };
  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);
  useEffect(() => {
    ; (async () => {
      const address = await generateRandomId()
      if(!address){
        SeRestrict(true)
      }
      if(!user_id){
      try {
          const Res = await USerRegister(BASE_URL,address);
          if(Res.status==1){
         Setload(false)
          }  
        }
         catch (error) {
        console.log(error)
       }
      }
      else{
        Setload(false)
      }
    })()
  },[]); 

  return (
    <>
    {Restrict&&<Restricted></Restricted>}
     {/* {load &&!Restrict&& <div className='center_vh'>
              <img  src={loader} alt="loader"></img>
            </div>
            } */}
{   !Restrict&&  !load &&<GameRoute></GameRoute>
}    </>
  )
}

export default Home
