import Cookies from 'js-cookie';
const COOKIE_NAME = 'userId';
const  COOKIE_TOKE= 'idtoken';
export const checkAndHandleUserId  =(navigate,ip) => {
    return {
        BASE_URL : 'https://tournamentapi.mchamp.xyz',
        tok :Cookies.get(COOKIE_TOKE) 
    };
  };
let x=checkAndHandleUserId();
    export const BASE_URL=x.BASE_URL
    export const user_id=x.useId
    export const tok=x.tok
