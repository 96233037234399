import React from 'react'
import GameHead from '../Game/GameHead'
import loader from '../../images/loader.gif'
import Navbar from '../header/Navbar'
import Right from '../header/Right'
import { BASE_URL,user_id,tok} from '../AllApi/CommonUrl';
import { gameData } from '../AllApi/GameApi';
import { useState, useEffect } from 'react'
import gameh from '../../images/TopBan21.jpg'
import QuizFooter from '../footer/QuizFooter';
import { Checkstatus } from '../AllApi/CheckStatus';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import FreePlay from '../Game/FreePlay'
const GameRoute = (props) => {
  const nav=useNavigate()
  let { id } = 214;
  const [arr, setArr] = useState([]);
  const [diamond, setdiamond] = useState(0);
  const [checkstatus,Setcheckstatus] = useState('');
  // const[avlcoins,setAvlcoins]=useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const generateRandomId = async() => {
    if (window && window.ethereum) {
      if (window.ethereum.isMiniPay) {
        // User is using Minipay
        // Requesting account addresses
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [],
        });
        // Injected wallets inject all available addresses,
        // to comply with API Minipay injects one address but in the form of array
        //console.log(accounts[0]);
         return accounts[0];
      }
      }
      // return '0x7071E17A089Bc6cED07BBAAd75887A318061AVDHESH1232323AD223'
  };
  const Internet=()=>{
    Swal.fire({
      title: "Network issue",
      text:"Weak  internet connection",
      allowOutsideClick: false,
      icon:'error'
    });
  }
  const Block=(message)=>{
    Swal.fire({
      title: message,
      allowOutsideClick: false,
      icon:'error'
    });
  }
  useEffect(() => {
    ; (async () => {
      const address = await generateRandomId()
      try {
        const gameEnd='goldgame/gameContestList'
        const game = await gameData(id,user_id,address,BASE_URL,gameEnd,tok);
        if(game.block==1){
          Block(game.message)
        }
        console.log( game)
         if(game==0){
          Internet();
         }
        setArr(game.result.game_details)
        setdiamond(game.coin_balance)
        setIsLoading(false);
           }  catch (error) {
        console.log(error)
      }
    })()
  }, []);
  const CheckingAlert = () => {
         Swal.fire({
           title: "Claim for your daily reward",
           text:"2500 Coins",
           showDenyButton: false,
           confirmButtonText: "Claim",
           allowOutsideClick: false
         }).then((result) => {
           /* Read more about isConfirmed, isDenied below */
           if (result.isConfirmed) {  
            nav(`/Checkin/${0}`)
           }
           else if (result.isDenied) {
           }
         });
       }

  useEffect(() => {
    ; (async () => {
      const address = await generateRandomId()
      try {
        const endpoint='CoinCheckin'
          const Res = await Checkstatus(BASE_URL,endpoint,address);
          console.log('resutl',Res)
            if(Res.check_in_screen==0){
              Setcheckstatus(0)
            } 
            else{
              Setcheckstatus(1)
            }   
        }
         catch (error) {
       }
    })()
  },[]); 
  return (
         <div className="section">
         <div className="s_left">
         <Navbar dmd={diamond}></Navbar>
         <div class="center top_banner22">
            <img src={gameh} className='top_img_bane' alt=''></img>
          </div>

          {/* <div className='text_top_nw'>
            <p class="game_ttl mb_10 grn">A great opportunity to win cUSD!</p>
            <p className='game_desc desc2'>Get into Exciting Gaming, a thrilling fusion of cUSD and gaming! Start by purchasing game coins with your cUSD & play interesting games tournaments. Showcase your skills by climbing the leader board and earn Diamonds. Convert diamonds into cUSD. </p>
          </div> */}

          {/* <div className='tlt'>
            <p class="game_ttl ">Live Tournaments</p>
        
          </div> */}
          <div className="game_list">
          {/* <p class="l_ttl">All Games</p> */}
          {isLoading && <div className='center_vh'>
              <img  src={loader} alt="loader"></img>
            </div>
            }
         {isLoading==false &&
          <GameHead arr={arr} status={checkstatus}></GameHead>
         }
          {isLoading==false &&
      <FreePlay arr={arr}></FreePlay>
  }
    </div>
    <QuizFooter></QuizFooter>

    </div>
    <Right></Right>

    </div>
  )
}

export default GameRoute
