import React from 'react'
import CateHeader from '../header/CateHeader'
import dollar from '../../images/celo_dollar.png'
import debit from '../../images/debit.png'
import diamond from '../../images/diamond.png' 
import credit from '../../images/credit.png'
import Right from '../header/Right'
import coin from '../../images/coin.png'
import { useEffect, useState } from 'react'
import { History } from '../AllApi/History';
import { BASE_URL} from '../AllApi/CommonUrl';
import Cookies from 'js-cookie';
const RedeemHist = () => {
  const user_id= Cookies.get('userId')
   const tok=Cookies.get('idtoken')
  const[hist,sethist]=useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ; (async () => {
      try {
        const EndPoint='offer/redeemUserDiamondHistory'
        const resp = await History(user_id,BASE_URL,EndPoint,tok);
        sethist(resp.result)
        setIsLoading(false)
                } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  return (
<>
      <div className="section">
      <div className="s_left">
      <CateHeader name={'Transaction History'} path={`/Progress/${0}`}></CateHeader>
      {
       isLoading===false && hist==null && <h6 style={{color:"red",textAlign:"center"}}>No history </h6>
      }
            <div className='game_list'>

      {  isLoading===false&& hist!=null && 
  hist.map((key,index) => {
    return(
        <div class="ldr_list">
          <div class="ldr_itm">
            <div className='ldr_sb_itm'>
            {key.msg!=null && key.credit_status==1&&key.transaction_type==1 &&
                <img src={credit} alt='credit' />
                }{key.msg!=null && key.credit_status==1&&key.transaction_type==3 &&
                  <img src={credit} alt='credit' />
                  }
                   {key.msg!=null && key.credit_status==1&&key.transaction_type==4 &&
            <img src={credit} alt='credit' />
            }
                {key.msg!=null && key.credit_status==2&& key.transaction_type==1 &&
                <img src={debit} alt='credit' />
                }
            
            {  key.credit_status==1&&key.transaction_type==2 &&
                <img src={credit} alt='credit' />
                }
                { key.credit_status==2&& key.transaction_type==2 &&
                <img src={debit} alt='credit' />
                }
            
              <div>                             
              {key.msg!=null &&  key.credit_status==1&& key.transaction_type==1 &&
                <p className='ldr_sb_itm'>Bought Coin </p>
                }
                {key.msg!=null &&  key.credit_status==2&& key.transaction_type==1 &&
              <div> <p className='ldr_sb_itm'>Deduct Coin </p>
                <p className='ldr_sb_itm g_name_walt'>{key.title}</p>
                </div>
                }
                {  key.credit_status==1&& key.transaction_type==2 &&
                  <div><p className='ldr_sb_itm'>Won Diamond </p>
                  <p className='ldr_sb_itm g_name_walt'>{key.title}</p>
                </div>            }
                {  key.credit_status==1&& key.transaction_type==3 &&
                <p className='ldr_sb_itm'>USDT Transferred</p>
                }
                  {  key.credit_status==1&& key.transaction_type==4&&
            <p className='ldr_sb_itm'>USDT Transferring</p>
            }
                { key.credit_status==2&& key.transaction_type==2 &&
                <p className='ldr_sb_itm'>Redeemed Diamonds </p>
                }
                <p className='tming'>{key.create_date}</p>
              </div>
            </div>
            {key.transaction_type==1 &&
            <div class="ldr_sb_itm entry_value">
            <p class="etry_am">
              <img src={coin}alt="diamond"></img>
              {key.diamond_quantity}
            </p>
          </div>
            }
            {key.transaction_type==2&&
            <div class="ldr_sb_itm entry_value">
            <p class="etry_am">
              <img src={diamond}alt="diamond"></img>
              {key.diamond_quantity}
            </p>
          </div>
      }
        {key.transaction_type==3&&
            <div class="ldr_sb_itm entry_value">
            <p class="etry_am">
              <img src={dollar}alt="diamond"></img>
              {key.diamond_quantity}
            </p>
          </div>
            }
              {key.transaction_type==4&&
         <div class="ldr_sb_itm entry_value">
         <p class="etry_am">
          In Process
         </p>
       </div>
        }
          </div>
        </div>
     
    )
  })
} 
</div>
      
      </div>
    <Right></Right>
    </div>
    </>
      )
}

export default RedeemHist
