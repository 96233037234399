
// const BASE_URL = 'http://fantasyapi.mchamp.com/';
import Cookies from 'js-cookie';
const COOKIE_NAME = 'userId';
const  COOKIE_TOKE= 'idtoken';
const  Bonus= 'coin';
export const USerRegister = async (BASE_URL,address) => {
   try {
       const Url = `${BASE_URL}/loginorregister`;
       const Idtoken ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY3Nzk5IiwidGltZVN0YW1wIjoiMjAyNC0wNS0xNyAwNTo1NTo0OSJ9.Dkt4VhHOJXINdDxwX1vPVmBQMhJFZ73FkJOBaVTtj78";
       const rawData ={
           "android_id":address,
           "gaid":address,
           "mobile_number":address,
           "login_type":"3",
           "email_id":"",
           "is_tc_accepted":"Yes",
           "imsi":"132",
           "imei":"1212",
           "brand":"",
           "model":"",
           "operator":"",
           "connection_type":"",
           "apk_version":"33",
           "android_version":"33",
           "package_version":"",
           "fb_first_name":"",
           "fb_last_name":"",
           "fb_email_id":"",
           "fb_image_url":"",
           "fb_id":"",
           "gm_first_name":"",
           "gm_last_name":"",
           "gm_email_id":"",
           "gm_image_url":""
           }
       const response = await fetch(Url, {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${Idtoken}`,
         },
         body: JSON.stringify(rawData),
       });
       if (!response.ok) {
         throw new Error('some error');
       }
       const jsonData = await response.json(); 
       console.log('resutld',jsonData)
       Cookies.set(COOKIE_NAME,jsonData.user_id,); 
       Cookies.set(COOKIE_TOKE,jsonData.idtoken,); 
       return jsonData;
           }
           catch (error) {
               console.error('Error fetching data:', error);
               return 0;
             }
  };
  
