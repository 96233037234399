import React from 'react'
import Right from '../header/Right'
import CateHeader from '../header/CateHeader'
import { NavLink } from 'react-router-dom'
import QuizFooter from '../footer/QuizFooter'
const HowPlay = () => {
    return (
        <>
            <div className="section">
                <div className="s_left">
                    <div className="header">
                            <CateHeader name={'How to Play'} path={'/gameroute/1'}></CateHeader>
                    </div>
                    {/* how to play content*/}
                    <div className="htplay game_det">
                        <h2 className="text_bg redd">How to Play</h2>
                        <div className="htP_text">
                            <p>1. Each Game Tournament can be played once daily.</p>
                            <p>2. To participate in each Tournament, the user needs 500 coins.</p>
                            <p>3. Coins will be added to the user's wallet daily as a bonus.</p>
                            <p>4. Each Game Tournament has 5 levels, and users must complete all the 5 levels.</p>
                            <p>5. The total score from the five levels will be summed and will be considered as the final score for the user.
                            </p>
                            <p>6. Once the Game Tournament time ends, the leaderboard will be created based on the users’ score.</p>
                            <p>7. The winning users will receive diamonds, as per the Diamond distribution for the Game Tournament.</p>
                            <p>8. After 24 hours of winning, diamonds will be credited to your account.</p>
                            <p>9. The won Diamonds can be further, redeemed into USDT.                            </p>

                        </div>
                    </div>
                    <QuizFooter></QuizFooter>
                </div>
                <Right></Right>
            </div>

        </>
    )
}

export default HowPlay
