import React from 'react'
import { useNavigate } from "react-router-dom";
import calender from '../../images/calender.svg'
const GameHead = (props) => {
    const navigate = useNavigate()
    const res=(constest)=>{
        const dataToSend = {
            name: "John Doe",
            age: 30
          };
          navigate(`/GameDetails/${constest}`);
        }
        // const res=(constest)=>{
        //     const dataToSend = {
        //         name: "John Doe",
        //         age: 30,
        //         constest:constest
        //       };
    
        // navigate("/GameDetails",{ state: dataToSend });
        //     //   navigate("/GameDetails", { state: dataToSend }); 
        //     }
  return (
    <>
              {props.arr!=null &&
                  props.arr.map((data, index) => { 
                    return(
                      <div className="game game3" onClick={() => res(data.contest_id)}>
                      <div className="g_tp_img">
                        <img src={data.image_url} alt={data.contest_name
}/>
                        <div className="played top_right">
                          <p>
                            <span className="circle pulsee greeen" />
                            Live Now
                          </p>
                        </div>
                        <div className="g_if_desc dsec_update">
                          <div className="w_70">
                            <p className="game_ttl game_ttl2">{data.contest_name}</p>
                            <p className="game_desc">
                            {data.games_discription}
                            </p>
                          </div>
                          <div class="pd_right">
                          {data.joinbuttontxt=="Completed" &&<button  className="btn_white btn_gren "onClick={() => res(data.contest_id)}>Played</button>

  }
  {data.joinbuttontxt=="Play Now" &&<button className="btn_white btn_gren pulsee" onClick={() => res(data.contest_id)}>Play To Win</button>

    }
                          {/* {data.joinbuttontxt=="Completed" &&<img src={plabutton} className="pulse"onClick={() => res(data.contest_id)} />

  }
  {data.joinbuttontxt=="Play Now" &&<img src={plabutton} className="pulse" onClick={() => res(data.contest_id)}/>

    } */}
                          </div>
                        </div>
                      </div>
                      
                      <div className="game_details3">
                        <div>
                          <p className="l_infogam">Prize Pool</p>
                          <p className="r_infogam">
                            <img src={data.prize_icon} alt="diamond" />
                            {data.max_prize_pool}
                          </p>
                        </div>
                        <div>
                          <p className="l_infogam">{data.contest_cost_text}</p>
                          <p className="r_infogam">
                            <img src={data.entry_icon} alt="coin" />
                            {data.contest_cost}
                          </p>
                        </div>
                        <div>
                          <p className="l_infogam">Ends In:</p>
                          <p className="r_infogam">
                            <img src={calender} alt="calender" />
                            {data.contest_played_date}(GMT +01:00)
                          </p>
                        </div>
                      </div>
                    </div>  
                )
            })
          }
    </>
  )
}

export default GameHead
