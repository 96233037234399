import React from 'react'

const Right = () => {
  return (
    <div className="s_right">
    <h1>Welcome to Trivia</h1>
  </div>
  
  )
}

export default Right
