import React from 'react'
import { SelfUser } from '../AllApi/SelfUserApi';
import { BASE_URL} from '../AllApi/CommonUrl';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { checkAndHandleUserId } from '../AllApi/CommonUrl';
import img1 from '../../images/1.jpg'
import img2 from '../../images/2.jpg'
import img3 from '../../images/3.jpg'
import twitter from '../../images/twitter.svg'
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
const PreWinn = (props) => {
  const navigate = useNavigate()
  const contest=props.contest
  const Contesttype=props.type
  const [you, setYou] = useState([]);
  const [name, setName] = useState('');
  const [diamond, Setdiamond] = useState('');
  const [flag, setFlag] = useState('');
  const [pic,Setpic] = useState('');
  const [pre_winners, setPre_winners] = useState([]);
const user_id= Cookies.get('userId')
 const tok=typeof (Cookies.get('logintoken'))==="undefined"|| Cookies.get('logintoken')==""?Cookies.get('idtoken'):Cookies.get('logintoken')
 const Internet=()=>{
  Swal.fire({
    title: "Network issue",
    text:"Weak  internet connection",
     allowOutsideClick: false,
    icon:'error'
  });
}
const url=`https://cash.minipay.xyz/browse?url=https://games.mchamp.xyz/GameDetails/${you.tounaments_id} #PlayOnMiniPay`
const twitterAppUrl = `twitter://post?message=${encodeURIComponent(`I played  ${you.game_name}  by @mChampApp in @minipay - I scored ${you.score} points  on my last game. Try it here so we can play together`)}&url=${encodeURIComponent(url)} #PlayOnMiniPay`;
const twitterWebUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`I played  ${you.game_name}  by @mChampApp in @minipay - I scored ${you.score} points  on my last game. Try it here so we can play together`)}&url=${encodeURIComponent(url)}`;
  const handleShare = () => {
    window.open(twitterWebUrl, "_self");
    setTimeout(() => {
      window.open(twitterWebUrl, "_self");
    }, 500);
  };
// const shareOnTwitter = (rank,) => {
//   const tweetText = `I recently played a ${you.game_name} game offered by @mchamp_tournament in @minipay - and in this interesting game,  and I achieved #${rank} rank. Along with that, I get ${(diamond*0.01)} Cusd winning amount. Try it using this link and let’s play together. `;
//   const encodedText = encodeURIComponent(tweetText);
//   const webTwitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}`;
//   const mobileTwitterUrl = `twitter://post?message=${encodedText}`;
//   if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
//     window.location.href = mobileTwitterUrl; 
//     setTimeout(() => {
//       window.open(webTwitterUrl, "_blank"); 
//     }, 2000);
//   } else {
//     window.open(webTwitterUrl, "_blank"); 
//   }
// };
 useEffect(() => {
  ; (async () => {
    try {
      if (!user_id) {
        navigate("/");
      }
      //  const PrevEnd = 'offer/previousContestWiseLeaderBoard'
      //   const previous = await PrevData(contest,user_id, BASE_URL, PrevEnd,tok);
      //   setPre_winners(previous.result)
      const UseEnd = 'userheaderdetail/selfUserPlayed/'
      const SelfUserData = await SelfUser(user_id,BASE_URL,contest,UseEnd,2,tok);
      console.log('self data',SelfUserData)
      if(SelfUserData==0){
        Internet();
       }
      if(SelfUserData.status==0){
        checkAndHandleUserId(navigate);
      }
      setFlag(SelfUserData.status)
      setYou(SelfUserData.result)
      setName(SelfUserData.profile.user_name)
      Setpic(SelfUserData.profile.user_image)
      Setdiamond(SelfUserData.profile.diamond_balance) //total diamond shown recommonded by vibhu sir 31 jan
    } catch (error) {
      console.log(error)
    }
  })()
}, []); 
 function formatContent(content) {
  const cleanedContent = content.replace(/[^a-zA-Z0-9 @.]/g, "");
    return cleanedContent.length > 10? cleanedContent.slice(20) : cleanedContent;
}      
 return (
    <>
      {/* <div class="no_task text">
                  You haven't completed any task yet.
              </div> */}
      <div id="test-swipe-2" className="col s12">
      <p className="text_detl">Winner  will be updated every 24hrs.</p>
      <div className="ldr_list bg_none">
          {props.prev!=0&&<div className="ldr_itm">
            <div className='ldr_new'>
              <div className="ldr_sb_itm">
                <p>Rank</p>
              </div>
              <div className="ldr_sb_itm">
                <p>Username</p>
              </div>

            </div>
            <div className="ldr_itm">
              <p>
                <span>Score</span>
              </p>
            </div>
          </div>}
        </div>

      {/* {
 props.prev==0 && 
  <p className="text_detl">Pre Winner  updating</p>
}  */}
      {flag!=0&&you!=0 &&you.game_name!=""&&
  <div class="ldr_update you_win">
  <div className='lrd_lft_u'>
    <div className='ldr_u_rnk'>
      <p className='rnk_u '>{you.rank}</p>
      {pic&&<img src={pic} alt={pic} className='img_ldr_usr'/>}
      {!pic&&<img src={img1} alt={img1} className='img_ldr_usr'/>}
    </div>
    {!name && <p>You</p>}
        {name!=null && <p>{formatContent(name)}</p>}
  </div>
  <div className='shar_btn'>
      <button  onClick={() => handleShare()}className='scr_rht share_btn_tt'><img src={twitter} alt='' />Share</button>
        <p class="scr_rht">{you.score!=0 && you.score}.0</p>
      </div>
</div>
}  {props.prev!=null && 
   props.prev.map((key,index) => {
    return(

      <div class="ldr_update">
      <div className='lrd_lft_u'>
        <div className='ldr_u_rnk'>
          <p className={`rnk_u ${ index+1==1?'rnk_1':index+1==2?'rnk_2':index+1==3?'rnk_3':''}`} >{index + 1}</p>
          {key.user_image&&<img src={key.user_image} alt={key.user_image} className='img_ldr_usr'/>}
                        {!key.user_image && index+1==1&& <img src={img1} alt={img1} className='img_ldr_usr'/>}
                        {!key.user_image && index+1==2&& <img src={img2} alt={img1} className='img_ldr_usr'/>}
                        {!key.user_image && index+1==3&& <img src={img3} alt={img1} className='img_ldr_usr'/>}
                        {!key.user_image && index+1!=1&&index+1!=2&&index+1!=3&& <img src={img1} alt={img1} className='img_ldr_usr'/>}        </div>
        {!key.user_name && <p>{key.android_id}</p>}
        {key.user_name && <p> {formatContent(key.user_name)}</p>}
      </div>
      <div className='shar_btn'>
      {/* <button   onClick={() => shareOnTwitter()}className='scr_rht share_btn_tt'><img src={twitter} alt='' />Share on Twitter</button> */}
        <p class="scr_rht">{key.score != 0 && key.score}.0</p>
      </div>
    </div>
    )
  })
}  
      </div>

    </>
  )
}

export default PreWinn
