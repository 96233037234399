import React from 'react'
import back from '../../images/back.svg'
import { NavLink } from "react-router-dom";
import how from '../../images/info.png'
const CateHeader = (props) => {
  return (
   <>
   <div className="header">
  <nav className="nav2">
    <div className='h_left'>
      <NavLink to={props.path} className="sidenav-trigger nav_inf">
        <img src={back} alt="back" className="back_ic" />
      </NavLink>
      <a href="#" className="nav_inf">
        <h2 className="nav_text">{props.name}</h2>
      </a>
    </div>
  </nav>
</div> 
   </>
  )
}
export default CateHeader
