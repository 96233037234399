import React from 'react'
import Right from '../header/Right'
import CateHeader from '../header/CateHeader'
import { NavLink } from 'react-router-dom'
import QuizFooter from '../footer/QuizFooter'
const FAQ = () => {
  return (
    <>
   <div className="section">
  <div className="s_left">
    <div className="header">
      <CateHeader name={'FAQ'} path={'/gameroute/1'}></CateHeader>
    </div>
    {/* how to play content*/}
    <div className="htplay game_det">
      <h2 className="text_bg redd">Q.1: What is a Game Tournament?</h2>
      <div className="htP_text">
        <p>
          Ans: A game tournament is a cryptocurrency gaming platform where users can participate based on their performance and have the chance to win significant amounts of cryptocurrency.
        </p>
      </div>
      <h2 className="text_bg redd">
        Q.2: How can I participate in the Game Tournament?</h2>
      <div className="htP_text">
        <p>
          Ans: You can participate in the games using coins, and each game has a different entry fee.</p>
      </div>
      <h2 className="text_bg redd">Q.3: Where can I get the coins? </h2>
      <div className="htP_text">
        <p>
          Ans: You can earn coins as daily rewards, and transactions on the platform will also apply.
        </p>
      </div>
      <h2 className="text_bg redd">
        Q.4: How many times can I play the game daily? 
      </h2>
      <div className="htP_text">
        <p>Ans: Each game tournament can only be played once per day.</p>
      </div>
      {/* <h2 className="text_bg redd">
        Q.5: What is the minimum user participation required in each Game
        Tournament?
      </h2>
      <div className="htP_text">
        <p>
          Ans: Minimum 5 users are required to participate in the Game
          Tournament, else the tournament will be cancelled, and an amount will
          be refunded to participating user’s wallet.
        </p>
      </div> */}
      <h2 className="text_bg redd">Q.5: What are the 5 levels in each game?</h2>
      <div className="htP_text">
        <p>
          Ans: After starting the game Users must complete all 5 levels, and the scores from all levels will be aggregated to determine the final score for that game tournament.</p>
      </div>
      <h2 className="text_bg redd">
        Q.6: When will the leaderboard be created?
      </h2>
      <div className="htP_text">
        <p>Ans: The leaderboard will be created once the game tournament ends.</p>
      </div>
      <h2 className="text_bg redd">
        Q.7:  Which cryptocurrency is used for transactions?
      </h2>
      <div className="htP_text">
        <p>Ans: We use USDT for all transactions.</p>
      </div>
      <h2 className="text_bg redd">Q.8: What is a Coin?</h2>
      <div className="htP_text">
        <p>
          Ans: A coin is an internal currency used by the site for participating in in-game tournaments.</p>
      </div>
      <h2 className="text_bg redd">Q.9: What is a Diamond?</h2>
      <div className="htP_text">
        <p>
          Ans: A diamond is also an internal currency used by the site. Users can earn diamonds by being listed on the leaderboard.</p>
      </div>
      <h2 className="text_bg redd">Q.10: How can I redeem diamonds?</h2>
      <div className="htP_text">
        <p>
          Ans: When you earn diamonds, they are added to your wallet. There is a redemption button located below the diamond amount. By clicking the redeem button, your diamonds will be converted to USDT and transferred to your crypto wallet.</p>
      </div>
      <h2 className="text_bg redd">Q.11: What we get on Daily Check-In?</h2>
      <div className="htP_text">
        <p>Ans: If a user’s daily balance is below 1500 coins, they will receive bonus coins. As maintaining a minimum balance of 1500 coins is required, to play all the games.</p>
      </div>
    </div>
    <QuizFooter></QuizFooter>
  </div>
  <Right></Right>
</div>

    </>
  )
}

export default FAQ
